<template>
    <div class="layout-header-section d-lg-flex d-block justify-content-between">
        <div class="header-label col-lg-5 col-12">Teams Outgoing Call Logs</div>
        <div class="
        col-lg-7 col-12
        text-end
        d-flex
        justify-content-end
        custom-flex-cloumn-mob
      ">
            <div class="service-plan-box-outer">
                <div class="d-flex justify-content-between align-items-center">
                    <div class="call-label">Bill Duration : </div>
                    <div class="call-count ps-1">
                        <span v-if="!loading">{{ totalcallduration ? totalcallduration : 0 }}</span>
                                <div class="
                                    spinner-border
                                    text-light
                                    custom-spinner-icon-loader-btn
                                  " role="status" v-if="loading">
                                    <span class="visually-hidden">Loading...</span>
                                </div>
                    </div>
                </div>
            </div>
            <button type="button" @click="outLogFilterModalOpen()" class="header-filter-btn-outer btn btn-light"
                title="Filter">
                <span class="custom-filter-apply-alert"></span>
                <i class="pi pi-filter-fill custom-text-primary" aria-hidden="true"></i>
            </button>
        </div>
    </div>
    <ul class="nav nav-tabs" id="myTab" role="tablist" v-if="this.user_team_status_allow == 1">
    <li class="nav-item" role="presentation">
      <button class="nav-link" id="myvoter-all-details-tab" data-bs-toggle="tab" data-bs-target="#myvoteralldetail"
        type="button" role="tab" aria-controls="vehicle-details" aria-selected="false" @click="redirectmyvoterurl()"><i class="pi pi-user me-1" :style="{ fontSize: '0.7rem' }"></i>My Outgoing Call Logs
      </button>
    </li>
    <li class="nav-item" role="presentation" >
      <button class="nav-link active" id="teamvoter-report-tab" data-bs-toggle="tab" data-bs-target="#teamvoterreport"
        type="button" role="tab" aria-controls="customer-details" aria-selected="true"><i class="pi pi-users me-1" :style="{ fontSize: '0.7rem' }"></i>Teams Outgoing Call Logs
      </button>
    </li>
  </ul>
  <div class="tab-content mt-2" id="myTabContent">
    <div class="tab-pane fade show active" id="myvoteralldetail" role="tabpanel"
      aria-labelledby="myvoter-all-details-tab">
        <div class="custom-ultima-datatable" v-bind:style="[this.user_team_status_allow == 1 ? { height: 'calc(100vh - 188px)' } : { height: 'calc(100vh - 185px)' }]">
            <DataTable :value="outgoingCallLogsList" :scrollable="true" columnResizeMode="fit" scrollHeight="flex"
                :paginator="true" :rows="30" :lazy="true" dataKey="lj1" :rowHover="true" :totalRecords="totalRecords"
                @page="changePage($event)"
                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                responsiveLayout="scroll" currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                :loading="loading">
                <template v-if="!loading" #empty>No records found.</template>
                <template #loading><i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i></template>
                <Column field="datatime" header="Date Time" headerStyle="width: 11%" bodyStyle="width: 11%">
                <template #body="{ data }">
                    <div>
                        {{ format_datetime(data.datetime) }}
                    </div>
                </template>
            </Column>
            <Column field="callednumber" header="Called Number" headerStyle="width: 15%" bodyStyle="width: 15%">
                <template #body="{ data }">
                    <div class="w-100">
                        <div class="text-capitalize mb-1">{{ data.lj8 ? data.lj8 : "N/A" }}
                        </div>
                        <div class="text-capitalize">{{ data.lj29 ? data.lj29 : "N/A" }}
                        </div>
                    </div>
                </template>
            </Column>
            <Column field="callstatus" header="Call Status" headerStyle="width: 10%" align="center" bodyStyle="width: 9%">
                <template #body="{ data }">
                    <span>{{ data.lj16 ? data.lj16 : "N/A" }}</span>
                </template>
            </Column>
            <Column field="callby" header="Call By" headerStyle="width: 19%" bodyStyle="width: 21%">
                <template #body="{ data }">
                    <div class="w-100">
                        <div class="text-capitalize mb-1">{{ data.lj15 ? data.lj15 : "N/A" }}
                        </div>
                        <div class="text-capitalize">{{ data.lj28 ? data.lj28 : "N/A" }}
                        </div>
                    </div>
                </template>
            </Column>
            <Column field="employeeduration" header="User Duration" headerStyle="width: 8%" bodyStyle="width: 8%">
                <template #body="{ data }">
                    <span>{{ data.lj33 ? data.lj33 : "N/A" }}</span>
                </template>
            </Column>
            <Column field="customerduration" header="Voter Duration" headerStyle="width: 8%" bodyStyle="width: 8%">
                <template #body="{ data }">
                    <span>{{ data.lj14 ? data.lj14 : "N/A" }}</span>
                </template>
            </Column>
            <Column field="billedduration" header="Bill Duration" headerStyle="width: 8%" bodyStyle="width: 8%">
                <template #body="{ data }">
                    <span>{{ data.lj35 ? data.lj35 : "N/A" }}</span>
                </template>
            </Column>
            <Column field="playRecording" header="Recording" headerStyle="width: 10%" bodyStyle="width: 9%">
                <template #body="{ data }">
                    <div>
                        <div v-if="data.lj18 != null && data.lj18 != ''">
                            <button type="button" title="Play" class="btn custom-outline-view-btn me-2"
                                @click="playRecordingModelOpen(data)">
                                <i class="pi pi-external-link  call-btn-color"></i>
                            </button>
                            <button type="button" title="Download" class="btn custom-outline-view-btn me-1">
                                <a target="_blank" :href="data.lj18" download="sample">
                                    <i class="pi pi-download call-btn-color"></i>
                                </a>
                            </button>
                        </div>
                        <div v-else>N/A</div>
                    </div>
             </template>
            </Column>
            </DataTable>
        </div>
    </div>
    <div class="tab-pane fade" id="teamvoterreport" role="tabpanel" aria-labelledby="teamvoter-report-tab"
      v-if="this.user_team_status_allow == 1">
      Teams Outgoing Call Logs
    </div>
  </div>

    <!-- Play Recording start here -->
    <div class="modal-mask" v-if="playrecordingmodelflag" >
        <div class="
        modal-sm modal-dialog-scrollable modal-fullscreen
        ms-auto
        custom-modal-outer
      " style="width: 30%; height:95%;">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="staticBackdropLabel">Call Recording</h5>
                    <button type="button" class="btn-close" @click="playRecordingModelClose()"></button>
                </div>
                <div class="modal-body" >
                    <div class="row">
                     <div class="mt-3">
                            <audio controls controlsList="download" style="width: 90%;">
                                <source :src="recordingUrl" type="audio/mpeg" />
                            </audio>
                         </div>
                    </div>
                </div>
                    <div class="modal-footer">
                    <div class="col-12 m-0">
                        <div class="d-flex justify-content-between">
                            <button class="btn filter-apply-btn" @click="playRecordingModelClose()">
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Play Recording end here -->

    <!-- filter start here -->
    <div class="modal-mask" v-if="outboundlogfiltermodalsflag">
        <div class="
        modal-sm modal-dialog-scrollable modal-fullscreen
        ms-auto
        custom-modal-outer
      ">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="staticBackdropLabel">Filter By</h5>
                    <button type="button" class="btn-close" @click="outLogFilterModalClose()"></button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-12">
                            <div class="custom-form-group">
                                <label class="form-label">From Date</label>
                                <Calendar v-model="fromdateout" :showIcon="true" class="custom-ultima-calendar"
                                    placeholder="DD/MM/YYYY" dateFormat="dd/mm/yy" :maxDate="todaysDate"
                                    :manualInput="false" :monthNavigator="true"
                                    :yearRange="'2020:' + new Date().getFullYear()" appendTo="body" :yearNavigator="true" />
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-12">
                            <div class="custom-form-group">
                                <label class="form-label">To Date</label>
                                <Calendar v-model="todateout" :disabled="fromdateout == null" :showIcon="true"
                                    class="custom-ultima-calendar" placeholder="DD/MM/YYYY" dateFormat="dd/mm/yy"
                                    :minDate="fromdateout" :maxDate="todaysDate" :manualInput="false" :monthNavigator="true"
                                    :yearRange="'2020:' + new Date().getFullYear()" appendTo="body" :yearNavigator="true" />
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12 col-md-12 col-12">
                            <div class="custom-form-group">
                                <label class="form-label">Voter Mobile No.</label>
                                <div class="input-group custom-input-group">
                                    <div class="col-lg-3 col-md-3 col-12">
                                        <Multiselect v-model="countrycode" :options="countryCodeList" :searchable="true"
                                            label="label" placeholder="Select" disabled
                                            class="multiselect-custom custom-title-multiselect" :canClear="true"
                                            :closeOnSelect="true" :object="true" noOptionsText="No Result"
                                            :canDeselect="false" />
                                    </div>
                                    <div class="col-lg-9 col-md-9 col-12">
                                        <input type="text" v-model="lj8" class="form-control" id="formMobileNoInput"
                                            placeholder="Enter Mobile No." maxlength="10" @paste="onPasteMobile" @keypress="onlyNumberMobile"
                                            autocomplete="off" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12 col-md-12 col-12">
                            <div class="custom-form-group">
                                <label class="form-label">Call Status</label>
                                <div class="custom-group-radio-box-btn">
                                    <input type="radio" class="btn-check" v-model="lj16" name="callstatus-option"
                                        id="misscall" autocomplete="off" value="Missed" />
                                    <label class="btn custom-radio-box-btn text-capitalize" for="misscall">Missed</label>
                                    <input type="radio" class="btn-check" v-model="lj16" name="callstatus-option"
                                        id="connectedcall" autocomplete="off" value="Connected" />
                                    <label class="btn text-capitalize" for="connectedcall">Connected</label>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12 col-12">
                            <div class="custom-form-group">
                                <label class="form-label">Voter Name</label>
                                <input type="text" v-model="lj29" @keypress="isLetterWithSpace($event)"
                                    class="form-control text-capitalize" placeholder="Enter Voter Name" maxlength="30"
                                    autocomplete="off" />
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12 col-12">
                            <div class="custom-form-group">
                                <label class="form-label">User Name</label>
                                <Multiselect v-model="searchuser_fk" :options="activeUserList" :searchable="true" label="label"
                                    placeholder="Select" class="multiselect-custom text-capitalize" :canClear="true"
                                    :closeOnSelect="true" :object="true" noOptionsText="No Result" appendTo="body" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <div class="col-12 m-0">
                        <div class="d-flex justify-content-between">
                            <button class="btn filter-apply-btn" 
                            :disabled="fromdateout == null && todateout == null && lj8 == '' && lj16 == '' && lj29 == '' && searchuser_fk == ''"
                            @click="
                                btnFilterSearch(lj8, lj16, lj29, searchuser_fk)
                                ">
                                Apply Filter
                            </button>
                            <button class="btn btn-link filter-reset-btn" @click="resetVoterFilter">
                                Reset All
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- filter end here -->
</template>
<script>
import ApiService from "../../service/ApiService";
import moment from 'moment';
export default {
    data() {
        return {
            outgoingCallLogsList: null,
            totalRecords: 0,
            page_no: 0,
            loading: false,
            totalcallduration: 0,
            playrecordingmodelflag: false,
            outboundlogfiltermodalsflag: false,
            countryCodeList: [],
            countrycode: { value: 101, label: "+91" },
            todaysDate: new Date(),
            fromdateout: null,
            todateout: null,
            lj8: '',
            lj16: '',
            lj29: '',
            searchuser_fk: [],
            activeUserList: [],
            searchuservalue: '',
            user_info: '',
            user_team_status_allow: 0,

        };
    },
    mounted() {
        this.getoutgoingcalllogs({ isTeam: 1 });
        this.user_info = JSON.parse(localStorage.user);
        this.user_team_status_allow = this.user_info.is_team;
    },
    ApiService: null,
    created() {
        this.ApiService = new ApiService();
    },
    methods: {
        redirectmyvoterurl() {
      this.$router.push("/logs/myoutgoing");
    },
        getoutgoingcalllogs(e) {
            this.loading = true;
            this.ApiService.getoutgoingcalllogs(e).then((items) => {
                if (items.status == 200) {
                    this.outgoingCallLogsList = items.data;
                    this.totalcallduration = items.totalBillingDuration;
                    this.totalRecords = items.count;
                    this.loading = false;
                } else {
                    this.loading = false;
                    this.outgoingCallLogsList = null;
                    this.totalRecords = 0;
                }
            });
        },
        changePage(event) {
            this.page_no = event.page;
            this.getoutgoingcalllogs({
                page_no: this.page_no, isTeam: 1, fromdate: this.fromdateout,
                todate: this.todateout,
                lj8: this.lj8,
                lj16: this.lj16,
                lj28: this.searchuser_fk,
                lj29: this.lj29
            });
        },
        format_datetime(value) {
            if (value) {
                return moment(String(value)).format("DD/MM/YYYY @ hh:mm A");
            } else {
                return 'N/A';
            }
        },
        onPasteMobile(event) {
      let mobvalue = event.clipboardData.getData('text');
      if (/^[0-9]+$/.test(mobvalue)) return true;
      else event.preventDefault();
    },
        onlyNumberMobile($event) {
            let keyCode = $event.keyCode ? $event.keyCode : $event.which;
            if (keyCode < 48 || keyCode > 57) {
                $event.preventDefault();
            }

            var mobileInput =  document.getElementById("formMobileNoInput");
            if((mobileInput.value == '' || mobileInput.length > 1) && (keyCode == 48 || keyCode == 49 || keyCode == 50 || keyCode == 51 || keyCode == 52)){
                $event.preventDefault();
            }
        },
        playRecordingModelOpen(data){
            this.playrecordingmodelflag = true;
            this.recordingUrl = data.lj18;
        },
        playRecordingModelClose(){
            this.playrecordingmodelflag = false;
        },
        outLogFilterModalOpen() {
            this.outboundlogfiltermodalsflag = true;
            if (this.fromdateout != null) {
                this.fromdateout = new Date(this.fromdateout);
            }
            if (this.todateout != null) {
                this.todateout = new Date(this.todateout);
            }
            this.getactiveusers();
        },
        outLogFilterModalClose() {
            this.outboundlogfiltermodalsflag = false;
        },
        btnFilterSearch(lj8, lj16, lj29, event_searchuser
        ) {
            if (this.fromdateout != null && this.todateout != null) {
                this.fromdateout = moment(this.fromdateout).format('YYYY-MM-DD');
                this.todateout = moment(this.todateout).format('YYYY-MM-DD');
            }
            this.searchuser_fk = event_searchuser;
            this.lj8 = lj8;
            this.lj16 = lj16;
            this.lj29 = lj29;
            this.outboundlogfiltermodalsflag = false;
            this.getoutgoingcalllogs({
                page_no: this.page_no,
                isTeam: 1,
                fromdate: this.fromdateout,
                todate: this.todateout,
                lj8: this.lj8,
                lj16: this.lj16,
                lj29: this.lj29,
                lj28: this.searchuser_fk.label,
            });
        },
        resetVoterFilter() {
            this.fromdateout = null;
            this.todateout = null;
            this.lj8 = '';
            this.lj16 = '';
            this.lj29 = '';
            this.searchuser_fk = '';
            this.getoutgoingcalllogs({
                page_no: this.page_no,
                isTeam: 1
            });
            this.outboundlogfiltermodalsflag = false;
        },
        isLetterWithSpace(e) {
            let charspace = String.fromCharCode(e.keyCode);
            if (/^[A-Za-z\s]+$/.test(charspace)) return true;
            else e.preventDefault();
        },
        getactiveusers(e) {
            this.ApiService.getactiveusers(e).then((data) => {
                if (data.status == 200) {
                    this.activeUserList = data.data;
                } else {
                    this.activeUserList = null;
                }
            });
        },
    },
};
</script>
<style scoped>
.status-active {
    background: #c8e6c9;
    border-radius: 2px;
    letter-spacing: 0.3px;
    color: #256029;
    opacity: 1;
    font-size: 12px;
    font-family: "AcuminPro-Bold";
    padding: 0.3em 0.2rem 0.2em 0.2rem;
    text-transform: capitalize;
    border: 1px solid #4daa53;
    line-height: 12px;
}

.status-inactive {
    background: #ffcdd2;
    border-radius: 2px;
    letter-spacing: 0.3px;
    color: #c63737;
    opacity: 1;
    font-size: 12px;
    font-family: "AcuminPro-Bold";
    padding: 0.3em 0.2rem 0.2em 0.2rem;
    text-transform: capitalize;
    border: 1px solid #d7a5a5;
    line-height: 12px;
}

.service-plan-box-outer {
    background: #e4f8f0 0% 0% no-repeat padding-box;
    border: 1px solid #e0e5ed;
    border-radius: 4px;
    padding: 6px 10px;
}

.service-plan-box-outer .call-label {
    font-family: "AcuminPro-SemiBold";
    font-size: 14px;
    letter-spacing: 0.28px;
    color: #2b2e38;
    line-height: 20px;
    user-select: none;
}

.service-plan-box-outer .call-count {
    font-family: "AcuminPro-SemiBold";
    font-size: 16px;
    letter-spacing: 0.32px;
    color: #4a5463;
    text-align: left;
    line-height: 16px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}</style>